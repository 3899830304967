export default {
  scentbirdPerfumeBlog: 'https://www.scentbird.com/blog/perfume-subscription/',
  scentbirdPodcast: 'https://podcast.scentbird.com/',
  driftHowItWorksVideo: 'https://www.youtube.com/embed/bdQqwYSS0Jw',
  cleanFragrancesVideo: 'https://www.youtube.com/embed/Eq3QgPb5GY0',
  ripplingJobs: 'https://ats.rippling.com/scentbird/jobs',
  scentbirdCologneBlog: 'https://www.scentbird.com/blog/cologne-subscription/',
  influencersSocialVideo: 'https://www.youtube.com/embed/TguzuPDAbxQ',
  neimanMarcus: 'https://www.neimanmarcus.com/',
}
